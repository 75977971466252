export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Giriş',
      breadcrumb: [
        {
          text: 'Giriş',
          active: true,
        },
      ],
      resource: 'DASHBOARD',
      action: 'read',
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/views/Payment/Index.vue'),
    meta: {
      pageTitle: 'Online Ödeme',
      breadcrumb: [
        {
          text: 'Online Ödeme',
          active: true,
        },
      ],
      resource: 'PAYMENT',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/Auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
]
