import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    paymentForm: {
      company: null,
      taxNo: null,
      taxOffice: null,
      phone: null,
      address: null,
      nameSurname: null,
      cardNumber: '',
      expirationDate: null,
      cvv: null,
      amount: null,
      pay_amount: null,
      cardType: null,
      bank: null,
      bank_logo: null,
      installment: '0',
      id_pos_configs: null,
      selectInstallment: null,
    },
    installments: [],
    paymentSendForm: {
      pay3d: null,
      action: null,
      elements: {},
      error: null,
      result: {
        approved: null,
      },
      modal: false,
    },
  },
  getters: {
    getPaymentForm(state) {
      return state.paymentForm
    },
    getInstallments(state) {
      return state.installments
    },
    getPaymentSendForm(state) {
      return state.paymentSendForm
    },
  },
  mutations: {
    PAYMENT_SEND(state, data) {
      state.paymentSendForm = data
    },
    CARD_TYPE(state, data) {
      state.paymentForm.cardType = data
    },
    BANK(state, data) {
      state.paymentForm.bank = data
    },
    BANK_LOGO(state, data) {
      state.paymentForm.bank_logo = data
    },
    ID_POS_CONFIGS(state, data) {
      state.paymentForm.id_pos_configs = data
    },
    SELECT_INSTALLMENT(state, data) {
      state.paymentForm.selectInstallment = data
    },
    INSTALLMENTS(state, data) {
      state.installments = data
    },
    RESET_FORM(state) {
      state.paymentForm = {
        company: null,
        taxNo: null,
        taxOffice: null,
        phone: null,
        address: null,
        nameSurname: null,
        cardNumber: '',
        expirationDate: null,
        cvv: null,
        amount: null,
        pay_amount: null,
        cardType: null,
        bank: null,
        bank_logo: null,
        installment: '0',
        id_pos_configs: null,
        selectInstallment: null,
      }
    },
  },
  actions: {
    paymentSend({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/payments', data)
        .then(response => {
          commit('PAYMENT_SEND', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getCardType({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/bins', data)
        .then(response => {
          commit('CARD_TYPE', response.data.card_organization)
          commit('BANK', response.data.bank)
          commit('BANK_LOGO', response.data.bank_logo)
          commit('ID_POS_CONFIGS', response.data.pos.id_pos_configs)
          commit('INSTALLMENTS', response.data.pos.installments)
          commit('SELECT_INSTALLMENT', `${response.data.pos.selectInstallment}`)
        })
        .catch(error => console.error(error))
    },
    resetForm({ commit }) {
      commit('RESET_FORM')
    },
  },
}
