export default [
  {
    path: '/payment-histroy',
    name: 'PaymentHistory',
    component: () => import('@/views/PaymentHistory/Index.vue'),
    meta: {
      pageTitle: 'Ödeme Geçmişi',
      breadcrumb: [
        {
          text: 'Ödeme Geçmişi',
          active: true,
        },
      ],
      resource: 'PAYMENT_HISTORY_LIST',
      action: 'read',
    },
  },
  {
    path: '/payment-histroy/view/:id',
    name: 'PaymentHistoryView',
    component: () => import('@/views/PaymentHistory/View.vue'),
    meta: {
      pageTitle: 'Ödeme Geçmişi',
      breadcrumb: [
        {
          text: 'Ödeme Geçmişi',
          to: '/payment-histroy',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'PAYMENT_HISTORY_VIEW',
      action: 'read',
    },
  },
]
