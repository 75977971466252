import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    payments_history: [],
    payments_historyCount: 0,
    payments_historyTotal: 0,
    payment_history: {},
    payment_historySave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getPayments_history(state) {
      return state.payments_history
    },
    getPayments_historyCount(state) {
      return state.payments_historyCount
    },
    getPayments_historyTotal(state) {
      return state.payments_historyTotal
    },
    getPayment_history(state) {
      return state.payment_history
    },
  },
  mutations: {
    PAYMENTS_HISTORY_LIST(state, data) {
      state.payments_history = data
    },
    PAYMENT_HISTORY_VIEW(state, data) {
      state.payment_history = data
    },
    PAYMENTS_HISTORY_COUNT(state, data) {
      state.payments_historyCount = data
    },
    PAYMENTS_HISTORY_TOTAL(state, data) {
      state.payments_historyTotal = data
    },
  },
  actions: {
    payments_historyList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/payments_history', data)
        .then(response => {
          commit('PAYMENTS_HISTORY_LIST', response.data.data)
          commit('PAYMENTS_HISTORY_TOTAL', response.data.total)
          commit('PAYMENTS_HISTORY_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    payment_historyView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'payments.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/payments_history', data)
        .then(response => {
          commit('PAYMENT_HISTORY_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
