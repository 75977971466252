import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import smtpConfig from '@/store/core/smtpconfig'
import userTypes from '@/store/core/user_types'
import users from '@/store/core/users'
import payment from '@/store/vpos/payment'
import abilities from '@/store/core/abilities'
import company from '@/store/vpos/company'
import posConfigs from '@/store/vpos/pos_configs'
import banks from '@/store/vpos/banks'
import paymentsHistory from '@/store/vpos/payments_history'
import customers from '@/store/vpos/customers'
import contents from '@/store/core/contents'
import docsUpload from '@/store/vpos/docs_upload'
import announcements from '@/store/vpos/announcements'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    smtpConfig,
    userTypes,
    users,
    payment,
    abilities,
    company,
    posConfigs,
    banks,
    paymentsHistory,
    customers,
    contents,
    docsUpload,
    announcements,
  },
  strict: process.env.DEV,
})
