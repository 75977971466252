import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    company: {},
    companySave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCompany(state) {
      return state.company
    },
    getCompanySaveStatus(state) {
      return state.companySave
    },
  },
  mutations: {
    COMPANY_VIEW(state, data) {
      state.company = data
    },
    COMPANY_SAVE(state, data) {
      state.companySave = data
    },
  },
  actions: {
    companyView({ commit }) {
      axiosIns
        .post('/company')
        .then(response => {
          commit('COMPANY_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    companySave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/company/saveData', data)
        .then(response => {
          commit('COMPANY_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
