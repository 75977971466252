import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataItem: {},
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
  },
  mutations: {
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
  },
  actions: {
    getDataItem({ commit }, id) {
      axiosIns
        .post('/contents', { id })
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
